@import "tailwindcss/base"; 
@import "tailwindcss/components"; 
@import "tailwindcss/utilities";

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.op-spinner-container .op-spinner {
  border-top-color: rgb(99, 102, 241)!important;
}

input[type="radio"]:checked + label {
  @apply bg-gray-200;
}

@keyframes custom-pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes custom-shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-0.25rem, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(0.25rem, 0, 0);
  }
}

.pulse[animate="1"] {
  animation-name: custom-pulse;
  animation-timing-function: ease-in-out;
  animation-duration: .3s;
}

.shake[animate~="0"] {
  animation-name: custom-shake;
  animation-timing-function: ease-in-out;
  animation-duration: .3s;
}

button[disabled], .disabled {
  opacity: .75;
  pointer-events: none;
}

.big-card::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FBCFE8;
  transform-origin: center;
  transform: rotate(15deg);
  border-radius: 0.375rem;
  content: ' ';
}

.form-row:first-child, .form-group:first-child {
  margin-top: 0!important;
}

.big-card {
  max-width: 95%;
}

@media(min-width: 768px) {
  .big-card {
    max-width: 50%;
  }
}
